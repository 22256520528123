<template>
  <!-- display:none fixes issue with disapearing border when there is no content in body -->
  <tbody :class="[{ 'hidden': !Boolean(props.body.length), 'pointer-events-none relative opacity-30': props.loading }]">
    <slot
      v-for="(row, rowIndex) in props.body"
      :key="`row-${row.name}`"
      :name="`row-${row.name}`"
      v-bind="{
        totalColumnCount: props.totalColumnCount,
        row,
        rowIndex,
        toggleRowExpansion,
        expandedRows: props.expandedRows,
        expandable: row?.expandable,
        rowStyle: getRowStyle(row),
        rowClass: getRowClass(row),
      }"
    >
      <!-- Sometimes we want to target all rows based on some condition, so instead of using slot name based on
          a row name, header row name is the same for all rows -->
      <slot
        name="row-header"
        v-bind="{
          totalColumnCount: props.totalColumnCount,
          row,
          rowIndex,
          toggleRowExpansion,
          expandedRows: props.expandedRows,
          expandable: row?.expandable,
          rowStyle: getRowStyle(row),
          rowClass: getRowClass(row),
        }"
      >
        <tr
          v-if="row?.expandable"
          :class="getRowClass(row)"
          :style="getRowStyle(row)"
        >
          <td :colspan="props.totalColumnCount">
            <div
              v-if="row.expandable"
              class="flex w-full border-b border-blue p-2"
            >
              <UiButton
                variant="icon"
                class="ml-auto"
                @click="toggleRowExpansion($event, row)"
              >
                <UiIcon
                  name="arrow"
                  :width="12"
                  :height="12"
                  class="rotate-90 text-primary"
                  :class="[
                    { '!-rotate-90': props.expandedRows.includes(row.name) },
                  ]"
                />
                Rozwiń
              </UiButton>
            </div>
          </td>
        </tr>
      </slot>

      <tr
        :class="[...getRowClass(row), ...getHighlightedRowClass(row)]"
        :style="getRowStyle(row)"
      >
        <td
          v-if="props.multiselect"
          :class="getRowCellClass(_, row)"
        >
          <div
            class="relative flex h-full flex-col items-center justify-center"
          >
            <span
              v-if="props.showRowNumber"
              class="flex-1 text-sm font-medium text-primary"
            >{{ rowIndex + 1 }}</span>
            <UiCheckbox
              :id="row.name"
              :model-value="props.selectedRows"
              :value="row.name"
              variant="navy"
              size="small"
              :container-class="['justify-center, flex-1']"
              class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
              @change="(event) => handleCheckboxChange(event, row)"
            />
          </div>
        </td>

        <template v-if="Array.isArray(row.value)">
          <template
            v-for="(rowCell, rowCellIndex) in row.value"
            :key="`row-cell-${rowCell.name}`"
          >
            <slot
              v-if="isBodyCellVisible(rowCell)"
              v-bind="{
                row,
                rowCell,
                rowIndex,
                rowCellIndex,
                rowCellClass: getRowCellClass(rowCell, row),
                rowCellStyle: getRowCellStyle(rowCell, row),
                toggleRowExpansion,
                highlighted: isRowHighlighted(row),
                expandedRows: props.expandedRows,
                expandable: row?.expandable,
              }"
              :name="`rowCell-${rowCell.name}`"
            >
              <UiBaseTableBodyCell
                :style="getRowCellStyle(rowCell, row)"
                :class="getRowCellClass(rowCell, row)"
                :column="rowCell"
                :column-index="rowCellIndex"
              >
                <slot
                  :name="`rowCellInside-${rowCell.name}`"
                  v-bind="{
                    row,
                  }"
                >
                  {{ rowCell.value }}
                </slot>
              </UiBaseTableBodyCell>
            </slot>
          </template>
        </template>
      </tr>

      <slot
        :name="`expandable-content-${row.name}`"
        v-bind="{
          totalColumnCount: props.totalColumnCount,
          row,
          rowIndex,
          toggleRowExpansion,
          expandedRows: props.expandedRows,
          expandable: row?.expandable,
          rowStyle: getRowStyle(row),
          rowClass: getRowClass(row),
          rowCellClass: '',
          rowCellStyle: '',
        }"
      >
        <tr
          v-if="props.expandedRows.includes(row.name)"
          :class="getRowClass(row)"
          :style="getRowStyle(row)"
        />
      </slot>
    </slot>
  </tbody>
</template>

<script setup lang="ts">
import UiCheckbox from '../UiForm/UiCheckbox/UiCheckbox.vue'
import UiButton from '../UiButton/UiButton.vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import UiBaseTableBodyCell from './UiBaseTableBodyCell.vue'
import type { BodyProps, Column, Row, TableCellClickEmitValue } from './UiBaseTable.types'

const props = withDefaults(defineProps<BodyProps>(), {
  loading: false,
  selectedRows: () => [],
  expandedRows: () => [],
  highlightedRows: () => [],
  showRowNumber: true,
})

const emit = defineEmits<{
  (e: 'click:checkbox', value: TableCellClickEmitValue)
  (e: 'row-toggle', value: TableCellClickEmitValue)
}>()

function getRowStyle(rowData: Row) {
  if (props.rowStyle) {
    return props.rowStyle(rowData)
  }
}

function getRowClass(rowData: Row) {
  const rowStyleClass = []

  if (props.rowClass) {
    const rowClassValue = props.rowClass(rowData)

    if (rowClassValue) {
      rowStyleClass.push(rowClassValue)
    }
  }

  return [rowData.class || props.styles.tbody.tr, rowStyleClass]
}

function isRowHighlighted(row: Row) {
  const matchedRow = props.highlightedRows.find(item => item.row === row.name)
  return (matchedRow) ? matchedRow?.highlightType || 'success' : ''
}

function getHighlightedRowClass(row: Row) {
  const rowHighlightType = isRowHighlighted(row)
  if (rowHighlightType === '') {
    return []
  }

  const highlightTypes = {
    success: ['!bg-notification-success [&>td]:text-white'],
    error: ['!border-notification-error !bg-errors [&>td]:text-white [&>td]:!border-notification-error'],
  }

  return highlightTypes?.[rowHighlightType] || []
}

function getRowCellStyle(cellData: Column, rowData: Row) {
  if (props.rowCellStyle) {
    return props.rowCellStyle(cellData, rowData)
  }
}

function getRowCellClass(cellData: Column, rowData: Row) {
  const rowCellStyleClass = []

  if (props.rowCellClass) {
    const rowCellClassValue = props.rowCellClass(cellData, rowData)

    if (rowCellClassValue) {
      rowCellStyleClass.push(rowCellClassValue)
    }
  }

  return [cellData.class || props.styles.tbody.td, rowCellStyleClass]
}

function handleCheckboxChange(event: Event, row: any) {
  emit('click:checkbox', {
    originalEvent: event,
    column: row,
  })
}

function toggleRowExpansion(event: PointerEvent, column: Column) {
  emit('row-toggle', { originalEvent: event, column })
}

function isBodyCellVisible(column: Column) {
  if (!column) {
    return false
  }
  if (!('visible' in column)) {
    return true
  }

  return column.visible
}
</script>
